/* istanbul ignore file */
import { buildErrorHtml, ClientMessageType } from '../constants';

export function onMessage(event: MessageEvent): void {
    if (event?.data?.type === ClientMessageType.SW_REDIRECT) {
        redirectFrame(event);
    } else if (event?.data?.type === ClientMessageType.SW_ERROR) {
        renderError(event);
    }
}

/**
 * Change the iframe URL path, triggering a redirect
 */
function redirectFrame(event: MessageEvent): void {
    window.location.replace(event.data.url);
}

/**
 * render an error in the iframe
 */
function renderError(event: MessageEvent): void {
    document.getElementById('content').innerHTML =
        buildErrorHtml(event.data.errorCode, event.data.errorMessage);
}

