// TODO: refactor and remove react dependency after https://issues.amazon.com/issues/caracara-7959
import { ISatelliteProps, SatelliteClient } from 'CSC-UI-Module-Satellite';
import { NetworkError } from 'CSC-UI-Module-Commons';
import { ClientMessageType } from '../constants';
import { onMessage } from './workerClient';

const loadTime: number = Date.now();
const satelliteClient = new SatelliteClient<ISatelliteProps>();

navigator.serviceWorker.register('./service-worker.js', {scope: '/'})
    .then((registration: ServiceWorkerRegistration) => {
        // Wait until service worker is ready.
        return navigator.serviceWorker.ready;
    })
    .then((registration: ServiceWorkerRegistration) => {
        // get the satellite port
        return satelliteClient.getHandshakeEvent();
    })
    .then((handshakeEvent: MessageEvent) => {
        const swChannel = new MessageChannel();
        swChannel.port1.onmessage = onMessage;
        const message = {
            ...handshakeEvent.data,
            type: ClientMessageType.GET_PORT,
            satellitePort: handshakeEvent.ports[0],
            clientPort: swChannel.port2, 
            loadTime: loadTime,
        };
        navigator.serviceWorker.controller.postMessage(message, [handshakeEvent.ports[0], swChannel.port2]);
    })
    .catch((error) => {
        console.error(
            new NetworkError('Failed to get Satellite port.', error)
        );
    });
